/** Lazy Load View **/
function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/mapfeehead/_components/${view}.vue`)
}

let routes = [
	{
		path: 'map-fee-head',
		name: 'map-fee-head',
		component: loadView('index'),
		meta: {
			auth: true,
			permission: true,

		}
	}
];

export default routes;