import Rest from '@/library/Rest'

export const getGrades = ({commit, state}, payload) => {
    return new Promise((resolve, reject) => {

        //reset state
        state.grade.items = [];
        state.section.items = [];
        state.section.selected = '';
        state.grade.selected = '';

        state.grade.fetching = true;

        let endpoint = '/api/grades?rowsPerPage=25&sortBy=rank&descending=true';
        if (payload && payload.filter) {
            endpoint += '&filter=true';
        }

        Rest().get(endpoint).then(({data}) => {
            state.grade.items = data.data;
            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.grade.fetching = false;
        });
    });
};