import statesData from '@/assets/json/states.json'
import districtData from '@/assets/json/districts.json'
import cityData from '@/assets/json/cities.json'

export default class AddressHelper {

    getCities(cityId) {
        return AddressHelper.get('city', cityId);
    }

    getCitiesByDistrictId(districtId) {
        return this.getChildPlace('district', districtId);
    }

    getDistrictByStateId(stateId) {
        return this.getChildPlace('state', stateId);
    }

    getDistricts(districtId = null) {
        return AddressHelper.getChildPlace('district', districtId);
    }


    getState(stateId = null) {
        return AddressHelper.get('state', stateId);
    }

    getName(type, id) {
        let output = '';
        switch (type) {
            case 'state':
                statesData.map(function (acc) {
                    if (acc.id === id) output = acc;
                });
                break;
            case 'district':
                districtData.map(function (acc) {
                    if (acc.id === id) output = acc;
                });
                break;
            case 'city':
                cityData.map(function (acc) {
                    if (acc.id === id) output = acc;
                });
                break;

        }
        if (Object.keys(output).length > 0) return output.name;
        return '';
    }


    /**
     * @param placeType
     * @param id
     * @returns {*}
     */
    getChildPlace(placeType = 'district', id) {
        const data = placeType === 'district' ? cityData : districtData;
        if (id && typeof id === "object") {
            return data.filter(function (place) {
                if (id.indexOf(place[placeType + '_id']) > -1) {
                    return place;
                }
            });
        } else if (id && typeof id === 'number') {
            return data.filter(function (place) {
                if (place[placeType + '_id'] === id) return place;
            });
        }
        return data;
    }


    /**
     * Getters
     * @param placeType
     * @param id
     */
    get(placeType = 'state', id) {
        const data = placeType === 'state' ? statesData : placeType === 'city' ? cityData : districtData;

        /**
         * 1) If the param is array type return the list of places with in the array.
         * 2) If number the return single place with that id
         * 3) Else return all.
         */
        if (id && typeof id === "object") {
            return data.filter(function (place) {
                if (id.indexOf(place.id) > -1) return place;
            });
        } else if (id && typeof id === 'number') {
            return data.filter(function (place) {
                if (place.id === id) return place;
            });
        }
        return data;
    }
}