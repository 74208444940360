<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn
        small
        icon
        color="primary"
        class="pa-0 ma-1"
        slot="activator"
        v-on="on"
      >
        <v-icon small>{{ icon || 'add' }} </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title primary white--text">Add Branch</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="save"
          @keydown.native="form.errors.clear($event.target.name)"
          @keyup.enter="save"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="form.name"
                  name="name"
                  label="Name"
                  :error-messages="form.errors.get('name')"
                  :height="25"
                  autocomplete="off"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="warning" outlined @click="(dialog = false), form.reset()"
          >Cancel</v-btn
        >
        <v-btn color="success" outlined @click="save">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Form from '@/library/Form';
export default {
  props: ['icon', 'message', 'onSuccess'],
  data: () => ({
    dialog: false,
    form: new Form(
      {
        name: '',
      },
      '/api/account/inventory/branch'
    ),
  }),
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    save() {
      this.form.fireFetch = false;
      this.form.store().then((data) => {
        this.dialog = false;
        this.$emit('onSuccess');
      });
    },
  },
};
</script>
