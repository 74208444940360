var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-message-prompt",style:(_vm.extraSmall === true
      ? 'min-height:50px;padding:10px;border-radius:10px;'
      : '')},[_c('div',{class:'alert-type ' + _vm.classType}),_c('div',{class:'alert-icon ' + _vm.classType},[(_vm.type === 'info' || _vm.type === 'warning' || _vm.type === 'error')?_c('v-icon',[_vm._v("warning")]):_c('v-icon',[_vm._v("check_circle")])],1),_c('div',{staticClass:"alert-message-box",style:(_vm.extraSmall === true ? 'margin-top:0;' : '')},[_c('div',{staticClass:"alert-title"},[_c('h2',{style:(_vm.extraSmall === true
            ? 'font-size: 18px;line-height:1;color:#444;margin-bottom:5px;'
            : '')},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("title")],2),_c('div',{staticClass:"alert-message"},[_c('p',{style:(_vm.extraSmall === true
            ? 'font-size: 14px;font-weight:normal;line-height:1.3;'
            : '')},[_vm._v(" "+_vm._s(_vm.message)+" "),_vm._t("message")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }