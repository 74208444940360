<template>
  <v-app id="app">
    <transition name="router-anim">
      <router-view class="view" />
    </transition>
    <iframe
      style="margin-left: 300px;"
      id="recieve"
      :src="getDomain"
      width="0"
      height="0"
    >
      <p>Your browser does not support iframes.</p>
    </iframe>

    <iframe
      style="margin-left: 300px;"
      id="reporting"
      :src="getReportDomain + '/web'"
      width="0"
      height="0"
    >
      <p>Your browser does not support iFrames.</p>
    </iframe>
  </v-app>
</template>

<script>
import { getServiceDomain } from "./library/helpers";

export default {
  computed: {
    getDomain() {
      return this.getServiceDomain('reporting');
    },
    getReportDomain() {
      return this.getServiceDomain("reporting");
    },
  },
  methods: {
    getServiceDomain,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Dancing+Script:400,700&&subset=vietnamese");

.company-slogan {
  font-family: "Dancing Script", cursive;
  font-weight: 700;
  color: #777 !important;
}

.role-selecter {
  .v-card__text {
    padding: 0 !important;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#app {
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
  min-height: 100%;
  min-width: 100%;
}

.router-anim-enter-active {
  animation: coming 0.8s;
  animation-delay: 0.5s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going 0.8s;
}

@keyframes coming {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes going {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.theme--light.v-pagination .v-pagination__item--active {
  outline: none;
}

.theme--light.v-pagination .v-pagination__navigation {
  outline: none;
}

.data-represent {
  display: flex;
  margin-left: 20px;
  div {
    span {
      display: inline-block;
      border-radius: 2px;
      height: 15px;
      width: 15px;
      &.d-success {
        background-color: #4caf50 !important;
      }
      &.d-error {
        background-color: #ff5252 !important;
      }
      &.d-warning {
        background-color: #fb8c00 !important;
      }
      &.d-info {
        background-color: #999999 !important;
      }
      &.d-primary {
        background-color: #1976d2 !important;
      }
    }
    margin-right: 20px;
  }
}

.page-container-card {
  min-height: 250px;
}

.page-layout {
  .title {
    color: #666;
    font-weight: bold;
  }
}

.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
  border: 1px solid rgba(0, 0, 0, 0.54) !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  /*box-shadow: none !important;*/
  /*resize: none;*/
}

.overlay {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  .inner-overlay {
    background-color: white;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.7;
    display: block;
    padding: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s;
    &.dont-show {
      display: none;
    }
    p {
      margin-top: 22%;
      font-size: 25px;
      color: red;
      font-weight: bold;
      font-family: "arial";
      opacity: 1;
      text-align: center;
    }
  }
}
</style>
