import axios from 'axios'
import auth from './Auth'
import cookie from 'js-cookie'


import appConfig from './Config'

let instance = axios.create({
    baseURL: appConfig.BASE_URL,
    // timeout: 1000,
    withCredentials: false,
    //crossDomain: true,
    onUploadProgress: function (progressEvent) {
        // TRACK UPLOAD PROGRESS
    },

    onDownloadProgress: function (progressEvent) {
        // TRACK DOWNLOAD PROGRESS
    },
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Request Interceptor
 */
instance.interceptors.request.use(config => {
    if (auth.get() != null && auth.get() !== 'null') config.headers.common.Authorization = auth.get();

    config.headers.common.domain = window.location.host;
    let role = cookie.get('_role');
    if (role) {
        config.headers.common.Role = cookie.get('_role');
    }
    if (window.localStorage._currentFiscalYear) {
        const fiscal_year = JSON.parse(window.localStorage._currentFiscalYear);
        config.headers.common.FiscalYear = fiscal_year.id;
    }
    if (appConfig.APP_ENV === 'QA') {
        config.headers.common.domain = 'eacademy.sahakaryatech.com';
    }
    if (location.host === "lms.iot-nepal.com") {
        config.headers.common.domain = 'system.iot-nepal.com';
    }
    if (appConfig.APP_ENV !== 'QA' && appConfig.APP_ENV !== 'LIVE' && appConfig.APP_ENV !== 'DEV') {
        config.headers.common.domain = appConfig.APP_ENV;
    }

    return config
}, error => {
    return Promise.reject(error);
});

/**
 * Response Interceptor
 */
instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response) {
        if (error.response.status === appConfig.rest.UNAUTHORIZED) {
            auth.clear();
            window.location.replace('/login');
        }
    }
    return Promise.reject(error);
});

export default () => {
    return instance;
}
