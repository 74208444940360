import auth from './Auth'

export default class Permissions {
    constructor() {
        this.userPermission = auth.permissions();
    }


    /**
     * Check if one among the given permissions list are in the user's permission
     * @param requiredPermissions
     * @returns {boolean}
     */
    hasPermissionSoft(requiredPermissions) {
        if (this.userPermission && this.userPermission.length && typeof this.userPermission === 'object' && requiredPermissions.length && typeof requiredPermissions === 'object')
            for (let i = 0; i < requiredPermissions.length; i++) {
                if (this.userPermission.includes(requiredPermissions[i])) return true;
            }
        return false;
    }


    /**
     * Check if all among the given permissions list are in the user's permission
     * @param requiredPermissions
     * @returns {boolean}
     */
    hasPermissionHard(requiredPermissions) {
        if (this.userPermission && this.userPermission.length && typeof this.userPermission === 'object' && requiredPermissions.length && typeof requiredPermissions === 'object') {
            let $this = this;
            return requiredPermissions.every(per => $this.userPermission.indexOf(per) > -1);
        }
        return false;
    }

    getPermissions() {
        return this.userPermission;
    }


    /**
     * Check if the provided permissions are in the user's permission list
     * @param permissionList
     * @returns {boolean}
     */
    checkPermissions(permissionList) {
        if (permissionList && permissionList.length && permissionList.includes('&')) {
            const requiredPermissions = permissionList.split('&');
            return this.hasPermissionHard(requiredPermissions);
        } else if (permissionList && permissionList.length) {
            const requiredPermissions = permissionList.split('|');
            return this.hasPermissionSoft(requiredPermissions);
        }
        return false;
    }
}