import * as actions from "./actions";
import * as mutations from "./mutations";
import state from "./state";
import * as getters from "./getters";

export default {
    state,
    actions,
    mutations,
    getters
};
