/** Lazy Load View **/
function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/modules/dashboard/_components/${view}.vue`
    );
}

let routes = [
  {
    path: '',
    name: 'home',
    component: loadView('index'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: loadView('Profile/index'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: loadView('Setting/index'),
    meta: {
      auth: true,
    },
  },
];

export default routes;
