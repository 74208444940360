/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/account/_components/${view}.vue`)
}

let routes = [
    {
        name: 'account-head',
        path: '/account-head',
        component: loadView('AccountHead/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'journal_types',
        path: '/journal-type',
        component: loadView('journal_type/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'journal-entry',
        path: '/journal-entry',
        component: loadView('JournalEntry/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'ledger-head',
        path: '/ledger-head',
        component: loadView('LedgerHead/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'entry-list',
        path: '/entry-list',
        component: loadView('JournalList/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'general-payment',
        path: '/general-payment',
        component: loadView('GeneralPayment/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'general-receipt',
        path: '/general-receipt',
        component: loadView('GeneralReciept/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'account-statement',
        path: '/account-statement',
        component: loadView('AccountStatement/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'invoice',
        path: '/invoice',
        component: loadView('Invoice/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'dr-note',
        path: '/dr-note',
        component: loadView('DrNote/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'cr-note',
        path: '/cr-note',
        component: loadView('CrNote/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'trial-balance',
        path: '/trial-balance',
        component: loadView('TrialBalance/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'pl-account',
        path: '/pl-account',
        component: loadView('PLAccount/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'balance-sheet',
        path: '/balance-sheet',
        component: loadView('BalanceSheet/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }, {
        name: 'fiscal-year',
        path: '/fiscal-year',
        component: loadView('FiscalYear/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'post-opening',
        path: '/post-opening',
        component: loadView('PostOpening/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    } ,
    {
        name: 'journal-entry-edit',
        path: '/journal-entry/:id',
        component: loadView('JournalEntry/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }, {
        name: 'opening-balance-report',
        path: '/opening-balance-report',
        component: loadView('OpeningBalanceReport/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'income-expense-report',
        path: '/income-expense-report',
        component: loadView('IncomeExpenseReport/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    },
    {
        name: 'customer',
        path: '/customer',
        component: loadView('Customer/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }, {
        name: 'mapping-param',
        path: '/mapping-param',
        component: loadView('MappingParam/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }, {
        name: 'inventory-mapping',
        path: '/inventory-mapping',
        component: loadView('InventoryMapping/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }, {
        name: 'school-payment',
        path: '/school-payment',
        component: loadView('SchoolPayment/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }, {
        name: 'billing-book',
        path: '/billing-book',
        component: loadView('BillingBook/index'),
        meta: {
            auth: true,
            permission: 'subject-read',
        }
    }
];

export default routes;