<template>
  <!-- <v-btn outline small icon color="warning" v-if="$auth.can(permission)"><v-icon small>{{ icon || 'edit'}}</v-icon></v-btn> -->
  <v-dialog
    v-if="hasPermission && showButton"
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        x-small
        :outlined="outlined === undefined ? true : outlined"
        color="error"
        class="ml-1 mt-1 mb-1"
        v-on="on"
      >
        <v-icon small>{{ icon || 'clear' }}</v-icon>
        <strong class="ml-1">Delete</strong>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title pa-3 red white--text">
        <v-icon class="mr-2 white--text">warning</v-icon>
        Please, wait!
      </v-card-title>
      <v-card-text class="ma-0">
        <br />
        <alert-message
          extra-small
          type="error"
          title="Please wait !!"
          :message="'Make sure you are fully aware what you are doing. You can always contact us if you are confused or need any help.'"
        ></alert-message>

        <p class="ml-0 mr-0 mb-0 pb-0 mt-3">
          {{ description }}
        </p></v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          small
          @click="(dialog = false), $emit('disagree')"
          >No</v-btn
        >
        <v-btn
          color="error"
          text
          small
          @click="(dialog = false), $emit('agree')"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    icon: { required: false },
    permission: { required: false },
    message: { required: false },
    description: { required: false },
    color: { required: false },
    outlined: { required: false },
    disabled: { required: false, default: false },
    onCurrentBatch: { required: false, default: true },
  },
  data: () => ({
    dialog: false,
    hasPermission: false,
  }),
  computed: {
    showButton() {
      return true;
    },
  },
  mounted() {
    if (this.permission)
      // this.hasPermission = this.$permissions.checkPermissions(this.permission);
      this.hasPermission = this.$auth.can(this.permission);

    // if (this.permission) {
    //     const requiredPermissions = this.permission.split('|');
    //     this.hasPermission = this.$permissions.hasPermission(requiredPermissions);
    // }
  },
};
</script>
