<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        small
        icon
        color="primary"
        class="pa-0 ma-1"
        slot="activator"
        v-on="on"
      >
        <v-icon small>{{ icon || 'add' }} </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title primary white--text"
        >Add Category</v-card-title
      >
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="save"
          @keydown.native="form.errors.clear($event.target.name)"
          @keyup.enter="save"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs8>
                <v-text-field
                  v-model="form.name"
                  name="name"
                  label="Name"
                  :error-messages="form.errors.get('name')"
                  :height="25"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs4>
                <v-checkbox
                  color="blue"
                  v-model="form.is_primary"
                  label="Primary"
                >
                </v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="form.category_code"
                  label="4 Digit Category Code "
                  :height="25"
                  :error-messages="form.errors.get('category_code')"
                  :maxlength="max"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="form.parent_id"
                  :items="items"
                  :search-input.sync="search"
                  hide-no-data
                  item-text="Name"
                  item-value="id"
                  label="Parent Category"
                  :disabled="form.is_primary"
                  placeholder="Start typing to Search"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="warning" outlined @click="(dialog = false), form.reset()"
          >Cancel</v-btn
        >
        <v-btn color="success" outlined @click="save">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Form from '@/library/Form';
export default {
  props: ['icon', 'message', 'onSuccess'],
  data: () => ({
    dialog: false,
    form: new Form(
      {
        name: '',
        description: '',
        is_primary: false,
        parent_id: '',
        category_code: '',
      },
      '/api/account/inventory/category'
    ),
    entries: [],
    search: '',
  }),
  mounted() {},
  watch: {
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get('/api/account/inventory/category?parent=1&search=' + val)
          .then(({ data }) => {
            // this.entries = data.data
            this.entries = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },
  computed: {
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },
  methods: {
    save() {
      if (this.form.is_primary) this.form.is_primary = 1;
      else this.form.is_primary = 0;
      // this.form.parent_id = this.form.parent_id.id;
      this.form.store().then((data) => {
        this.dialog = false;
        this.form.is_primary = false;
        this.$emit('onSuccess');
      });
    },
  },
};
</script>
