import Rest from '@/library/Rest'

export default {
    setUser: ({commit}, payload) => {
        commit('USER', payload);
    },
    setBatch: ({commit}, payload) => {
        commit('BATCH', payload);
    },
    setFiscalyear:({commit},payload) => {
        commit('FISCAL_YEAR', payload);
    },
    setStudent: ({commit}, payload) => {
        commit('STUDENT', payload);
    },
    setBatches: ({commit}, payload) => {
        commit('BATCHES', payload);
    },
    getStudentInfo: ({commit, state, dispatch}, payload) => {
        // let $this = this;
        return new Promise((resolve, reject) => {
            return Rest().get(payload).then(({data}) => {
                dispatch('setStudent', data);
                resolve(data)
            }).catch(err => {
                reject(err);
            });
        });
        //
    }
}
