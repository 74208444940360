export default {
    grade: {
        items: [],
        fetching: false,
        selected: ''
    },
    section: {
        items: [],
        fetching: false,
        selected: ''
    }
};
