import ls from './Storage'
import encryption from './Encryption'
import cookie from 'js-cookie'

var jwtDecode = require('jwt-decode');

let auth = new (function () {
    let permissions = () => {
        try {
            return encryption.decryptObject(ls.get('_permissions'));
        } catch (e) {
            return null
        }
    };

    this.set = (payload) => {
        ls.set('_token', payload.token);
        ls.set('_user', payload.user);
        // cookie.set('_user', payload.user, {expires: 365});
        ls.set('_company', payload.company, {expires: 365});
        cookie.set('_cl', this.company('logo'), {expires: 365});
        // cookie.set('_batch', payload.batch, {expires: 365});
    };

    this.get = () => {
        let lib = ls.get('_token');
        return `${lib}`
    };

    this.setPermission = (permissions) => {
        ls.set('_permissions', permissions);
    };
    this.setRole = (role) => {
        ls.set('_role', role);
    };
    this.getRole = (role) => {
        return ls.get('_role', role);
    };

    this.loggedInRole = () => {
        let myRoleSlug = cookie.get('_role');
        let myRole = {};
        this.user().roles.map(function (role) {
            if (role.slug === myRoleSlug)
                myRole = role;
        });
        return myRole;
    };

    this.user = () => {
        try {
            return encryption.decryptObject(ls.get('_user'));
        } catch (e) {
            return null
        }
    };

    /**
     * pass role's slug to check if the current logged in user has that role or not.
     * @param role
     * @returns {boolean}
     */
    this.hasRole = (role) => {
        if (!role) return false;
        const {roles} = this.user();

        if (roles.length < 1) return false;
        let output = false;
        try {
            //try catch to break the loop.
            roles.map(function (item) {
                if (item.slug === role) {
                    output = true;
                    throw Error('Hello');
                }
            });
        } catch (e) {
        }
        return output;
    };

      /**
     * Check if the logged in user is eAcademy administrator
     * @returns {boolean}
     */
    this.isAdmin = () => {
        const user = this.user();
        return user.ut === "admin";
    };


    /**
     * Get the current batch saved from the logged in user's response
     * @returns {*}
     */
    this.getCurrentBatch = () => {
        try {
            return encryption.decryptObject(cookie.get('_batch'));
        } catch (e) {
            return null
        }
    };


    /**
     * Check authenticated.
     * @returns {boolean}
     */
    this.authenticated = () => {
        let data = ls.get('_token');

        if (data === undefined) return false;
        try {
            var decoded = jwtDecode(data);
            data = encryption.decrypt(decoded.sub);
            return !isNaN(data)
        } catch (e) {
            return false;
        }
    };

    this.permissions = () => permissions();

    this.can = (p) => {
        if (p === false) return false;
        return true;
        if (typeof p === 'boolean') return p;

        if ([undefined, null, ''].includes(p)) return false;
        let sign = '';


        if (p.includes('&')) sign = '&';
        else if (p.includes('|')) sign = '|';
        else return permissions().indexOf(p) !== -1;

        p = p.replace(/\s/g, '').split(sign);
        if (!p.length) return false;
        if (sign === '&') {
            return (p.every(r => {
                return (permissions().indexOf(r) !== -1)
            }));
        }
        else {
            return p.some(r => permissions().indexOf(r) !== -1)
        }
    };

    this.clear = () => {
        ls.clear()
    };

    this.company = (payload) => {
        try {
            const company = encryption.decryptObject(ls.get('_company'));
            if (!payload) return company;

            return company[payload] || {}
        } catch (e) {
            return {}
        }
    }
});

/*let auth = {
    set: (payload) => {
        ls.set('account', payload.token);
        ls.set('user', encryption.encryptObject(payload.user));
    },
    get: () => {
        let lib = ls.get('account')
        return `${lib}`
    },
    user: () => {
        try {
            return encryption.decryptObject(ls.get('user'));
        } catch(e) {
            return null
        }
    },
    authenticated: () => {
        let data = ls.get('account');
        if(data == undefined) return false;
        try {
            var decoded = jwtDecode(data);
            data = encryption.decrypt(decoded.sub)
            return !isNaN(data)
        } catch(e) {
            return false;
        }
    },
    permissions() {
        return []
    },
    can: (...p) => {
        if(!p.length) return false;
        return true;
        //return p.some(r => this.permissions().indexOf(r) !== -1).bind(this)
    },
    clear: () => {
        ls.clear()
    }
};*/

export default auth;
