import auth from '@/library/Auth';
import Permissions from '@/library/Permissions';

const permissions = new Permissions();

function requireAuth(to, from, next) {
  if (to.name !== 'login') {
    if (to.meta.auth && auth.authenticated()) {
      if (to.meta.permission && !auth.can(to.meta.permission)) {
        next(false);
      } else {
        document.title = to.name.toUpperCase();
        next();
      }
    } else if (from.name !== 'login') {
      document.title = 'LOGIN';
      next('/login');
    } else {
      next(false);
    }
  } else if (to.name === 'login') {
    if (auth.authenticated()) {
      next('/');
    } else {
      document.title = to.name.toUpperCase();
      next();
    }
  } else {
    if (to.meta.auth && auth.authenticated()) next(false);
    else {
      document.title = to.name.toUpperCase();
      next();
    }
  }
}

export default requireAuth;
