import Vue from 'vue'
import Vuex from 'vuex'

import states from './states'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'


import grade from './modules/grade/index';
import students from './modules/students/index';
import members from './modules/members/index';
import trialbalance from './modules/trialbalance/index'
import accounts from './modules/accounts/index'
import countData from './modules/countData/index'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: states,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {grade, students, members, trialbalance,accounts,countData},
});