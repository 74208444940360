import auth from './Auth'
import cookie from "js-cookie";


export default class NavFactory {
    user = {};

    static isClassTeacher() {
        let user = auth.user();
        let role = cookie.get('_role');
        return ((user && user.class_teacher) || role === 'administrator' || role === 'super');
    }

    static isDev() {
        return (location.host === 'dev.lekhangkan.com' || location.host === 'localhost:4000' || location.host === 'account.sahakaryatech.com');
    }

    static isSuper() {
        return (location.host === 'service.lekhangkan.com')
    }


    static isSchoolAccounting() {
        let pattern = new RegExp("^account[.]{1}[a-z]*[.]{1}.*");
        return (pattern.test(location.host))
    }
}