<template>
  <v-menu class="download_drop" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        :color="'success'"
        class="white--text"
        v-bind="attrs"
        v-on="on"
        outlined
        small
      >
        <v-icon left small>cloud_download</v-icon>
        &nbsp; <slot></slot>
      </v-btn>
    </template>

    <v-list dense>
      <v-subheader v-if="columnHeader.length">{{ columnHeader }}</v-subheader>
      <v-list-item v-for="(column, key) in downloadColumns" :key="key" link>
        <v-checkbox
          :disabled="column.disabled === true"
          v-model="column.selected"
          @change="updateSelectedColumn(column)"
          :label="column.name"
          :color="column.color ? column.color : 'success'"
          hide-details
        ></v-checkbox>
      </v-list-item>

      <!-- <v-list-item v-for="(column, key) in downloadColumns" :key="key" link> -->
      <!--  -->

      <!-- </v-radio-group> -->
      <!-- </v-list-item> -->
      <v-subheader v-if="hideDownload === false">ACTIONS</v-subheader>
      <v-list-item-group color="primary" v-if="hideDownload === false">
        <v-list-item v-if="showPDF" @click="download('pdf')" link>
          <v-list-item-title
            ><v-icon color="red" small left>picture_as_pdf</v-icon> &nbsp;{{
              downloadPDFText
            }}</v-list-item-title
          >
        </v-list-item>
        <v-list-item v-if="showExcel" link @click="download('excel')">
          <v-list-item-title
            ><v-icon color="success" small left>border_all</v-icon>&nbsp;&nbsp;
            {{ downloadExcelText }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
import { passDataToExternalDomain } from '../../library/helpers';
export default {
  props: {
    downloadColumns: {
      required: false,
      type: Array,
      default: () => {
        return [];
      },
    },
    showPDF: {
      required: false,
      default: true,
      type: Boolean,
    },
    showExcel: {
      required: false,
      default: true,
      type: Boolean,
    },
    // selectedItem:{
    //   required:false,
    //   type:Function
    // },
    optionType: {
      required: false,
      default: 'checkbox',
      type: String,
    },
    columnHeader: {
      required: false,
      default: '',
      type: String,
    },
    downloadExcelText: {
      required: false,
      default: 'Download Excel',
      type: String,
    },
    downloadPDFText: {
      required: false,
      default: 'Download PDF',
      type: String,
    },
    onDownload: {
      required: false,
      default: null,
      type: Function,
    },
    hideDownload: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {},
  },
  data: () => ({
    downloadColumnsInhouse: [],
  }),
  computed: {},
  watch: {},
  mounted() {
    this.downloadColumnsInhouse = this.downloadColumns;
  },
  methods: {
    updateSelectedColumn(column) {
      this.$emit('selectedItem', column);

      const selectedItems = [];
      this.downloadColumnsInhouse.map((item) => {
        if (item.selected === true) {
          selectedItems.push(item);
        }
      });
      this.$emit('selectedItems', selectedItems);

      if (this.optionType === 'radio') {
        this.downloadColumnsInhouse = this.downloadColumnsInhouse.map(
          (item) => {
            if (item.value === column.value) {
              item.selected = true;
            } else {
              item.selected = false;
            }
            return item;
          }
        );
      }
    },
    download(type) {
      const selectedColumn = this.downloadColumns.filter(
        (item) => item.selected === true
      );
      let columnString = [];
      selectedColumn.map((item) => {
        columnString.push(item.value);
      });
      columnString = columnString.join(',');

      if (selectedColumn.length > 0 || this.downloadColumns.length === 0) {
        passDataToExternalDomain('reporting');
        setTimeout(() => {
          this.$emit('onDownload', {
            type,
            columns: selectedColumn,
            columnArr: columnString.split(','),
            columnString,
          });
        }, 500);
      }
    },
  },
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
}
.v-menu__content .v-input--selection-controls {
  margin-top: 0 !important;
}

.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
}
</style>
