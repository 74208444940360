export default {
    staff: {
        items: [],
        filter: {
            role: ''
        }
        // fetching: false,
        // selected: ''
    },
    guardian: {
        items: [],
        filter: {}
        // fetching: false,
        // selected: ''
    }
};
