<template>
  <div
    class="alert-message-prompt"
    :style="
      extraSmall === true
        ? 'min-height:50px;padding:10px;border-radius:10px;'
        : ''
    "
  >
    <div :class="'alert-type ' + classType"></div>
    <div :class="'alert-icon ' + classType">
      <v-icon v-if="type === 'info' || type === 'warning' || type === 'error'"
        >warning</v-icon
      >
      <v-icon v-else>check_circle</v-icon>
    </div>
    <div
      class="alert-message-box"
      :style="extraSmall === true ? 'margin-top:0;' : ''"
    >
      <div class="alert-title">
        <h2
          :style="
            extraSmall === true
              ? 'font-size: 18px;line-height:1;color:#444;margin-bottom:5px;'
              : ''
          "
        >
          {{ title }}
        </h2>
        <slot name="title"></slot>
      </div>
      <div class="alert-message">
        <p
          :style="
            extraSmall === true
              ? 'font-size: 14px;font-weight:normal;line-height:1.3;'
              : ''
          "
        >
          {{ message }}
          <slot name="message"></slot>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      default: "info",
    },
    title: {
      required: false,
    },
    message: {
      required: false,
    },
    extraSmall: {
      type: Boolean,
    },
  },
  computed: {
    classType() {
      return this.type === "warning"
        ? "warning_t"
        : this.type === "success"
        ? "success_t"
        : this.type === "error"
        ? "error_t"
        : "info_t";
    },
  },
};
</script>
<style lang="scss">
.alert-message-prompt {
  width: 100%;
  padding: 15px 15px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  background: white;
  border-radius: 20px;
  min-height: 100px;
  .alert-type {
    height: inherit;
    border-radius: 10px;
    width: 6px;
    &.info_t {
      background: #1976d2;
    }
    &.warning_t {
      background: #f1a92d;
    }
    &.success_t {
      background: #22a67a;
    }
    &.error_t {
      background: #eb5a46;
    }
  }
  .alert-icon {
    &.info_t {
      .v-icon {
        color: #1976d2 !important;
        font-size: 35px;
      }
    }
    &.warning_t {
      .v-icon {
        color: #f1a92d !important;
        font-size: 35px;
      }
    }
    &.success_t {
      .v-icon {
        color: #22a67a !important;
        font-size: 35px;
      }
    }
    &.error_t {
      .v-icon {
        color: #eb5a46 !important;
        font-size: 35px;
      }
    }
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    align-items: center;
  }
  .alert-message-box {
    margin-top: 8px;
    width: 100%;
    p {
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
  .alert-title h2 {
    color: #282b35;
    font-weight: normal;
  }
  .alert-message {
    color: #94959a;
    font-size: 16px;
    width: 85%;
  }
}
</style>
