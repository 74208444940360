<template>
    <!-- <v-btn outline small icon color="warning" v-if="$auth.can(permission)"><v-icon small>{{ icon || 'edit'}}</v-icon></v-btn> -->
    <v-hover v-if="hasPermission">
        <v-btn @click="$emit('action')" small icon :color="color||'primary'" class="pa-0 ma-1">
            <v-icon color="white" small>{{ icon || 'crop_free'}}</v-icon>
        </v-btn>
    </v-hover>
</template>
<script>
    export default {
        props: ['icon', 'permission', 'color'],
        data: () => ({
            dialog: false,
            hasPermission: false
        }),
        mounted() {
            if (this.permission)
                this.hasPermission = this.$auth.can(this.permission);

            // if (this.permission) {
            //     const requiredPermissions = this.permission.split('|');
            //     this.hasPermission = this.$permissions.hasPermission(requiredPermissions);
            // }
        }
    }
</script>